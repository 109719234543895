import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";

import arrowImg from "../assets/Arrow.svg";

import styles from "./ItemDropdownSidenav.module.scss";

const ItemDropdownSidenav = ({ liList, img, name }) => {
  /**
   * This component need 3 props :
   * - name (string)
   * - img (imported image)
   * - liList (array of objects)
   *  You can give one object or as much as you want to liList
   *  {name: "string", path: "/your-path"}
   */
  const location = useLocation();

  const [dropdownClicked, setDropownClicked] = useState(false);

  const handleDropdownClick = () => {
    setDropownClicked(true);
  };

  const handleItemDropdownHover = () => {
    setDropownClicked(false);
  };

  const arrayOfLi = liList.map((obj) => (
    <Link
      onClick={() => {
        if (location.pathname.split("/")[1] === obj.path && obj.path !== "suivez-vos-contrats") {
          window.location.reload(false);
        }
      }}
      to={obj.path}
      key={nanoid()}
    >
      <li>
        <img src={obj.liImg} alt="" />
        <span>{obj.name}</span>
      </li>
    </Link>
  ));

  const displayValue = dropdownClicked ? "none" : "block";

  /* eslint-disable */
  return (
    <div className={styles.nav_bloc} onMouseEnter={handleItemDropdownHover}>
      <div className={styles.content_container}>
        <img src={img} alt="" />
        <div className={styles.name}>{name}</div>
      </div>
      <div className={styles.sub_nav} style={{ display: displayValue }} onClick={handleDropdownClick}>
        <ul>{arrayOfLi}</ul>
      </div>
      <img className={styles.arrow} src={arrowImg} alt="Flèche" />
    </div>
  );
};

export default ItemDropdownSidenav;
