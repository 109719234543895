import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";

import SubTitleInForm from "components/typography/SubTitleInForm/SubTitleInForm";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import {
  getEmployeesThunk,
  getEmployeeDetails,
  getEmployeeDetails2,
  getEmployeesThunk2,
  getCompanyDetailsIsPending,
} from "app/slices/checkDetailsCompany/checkDetailsCompany.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import {
  patchContractDataThunk,
  selectContractDataFYC,
} from "app/slices/followYourContracts/followYourContracts.slice";
import { formatDate } from "utils/formatDate";
import { FormDropdownField } from "pages/ENT/FollowYourContracts/components/formFields/FormDropdownField/FormDropdownField";
import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import { FormInputField } from "pages/ENT/FollowYourContracts/components/formFields/FormInputField/FormInputField";
import FormCustomCheckBoxField from "pages/ENT/FollowYourContracts/components/formFields/FormCustomCheckBoxField/FormCustomCheckBoxField";
import { FormDateField } from "pages/ENT/FollowYourContracts/components/formFields/FormDateField/FormDateField";
import { FormPhoneField } from "pages/ENT/FollowYourContracts/components/formFields/FormPhoneField/FormPhoneField";
import { createObjAucun } from "../../../../../../../../utils/localReferentials";

import styles from "./Form6.module.scss";

const Form6 = ({
  axiosCancelToken,
  submittingAllSections,
  submitNextSectionIfSubmitAllTrue,
  cancelSubmitAllSections,
  currentSectionBeingSubmitted,
  setFormIsValid,
  isOpen,
}) => {
  const dispatch = useDispatch();
  const isEmployeeDataPending = useSelector(getCompanyDetailsIsPending);

  const objAucun = createObjAucun();

  const phoneRegExp = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";

  const referencialData = useSelector(getReferentialsData);
  const contractData = useSelector(selectContractDataFYC);
  const employeeDetails = useSelector(getEmployeeDetails);
  const FORM = "FORM_6";

  const [formStep, setFormStep] = useState("");

  const [fromTakeInfo, setFromTakeInfo] = useState(false);
  const [checkboxSecondTutor, setCheckboxSecondTutor] = useState(false);
  const [fromTakeInfo2, setFromTakeInfo2] = useState(false);
  const employeeDetails2 = useSelector(getEmployeeDetails2);

  const timedOutRef = useRef(null);
  const timedOutRef2 = useRef(null);

  const [enableCivilityTutor1IfEmpty, setEnableCivilityTutor1IfEmpty] = useState(false);
  const [enableFunctionTutor1IfEmpty, setEnableFunctionTutor1IfEmpty] = useState(false);
  const [, setEnableHighestDegreeTutor1IfEmpty] = useState(false);
  const [enableBirthDateTutor1IfEmpty, setEnableBirthDateTutor1IfEmpty] = useState(false);

  const [enableCivilityTutor2IfEmpty, setEnableCivilityTutor2IfEmpty] = useState(false);
  const [enableFunctionTutor2IfEmpty, setEnableFunctionTutor2IfEmpty] = useState(false);
  const [, setEnableHighestDegreeTutor2IfEmpty] = useState(false);
  const [enableBirthDateTutor2IfEmpty, setEnableBirthDateTutor2IfEmpty] = useState(false);

  const handleResetStatesIfTutor1EmptyValue = () => {
    setEnableCivilityTutor1IfEmpty(false);
    setEnableFunctionTutor1IfEmpty(false);
    setEnableHighestDegreeTutor1IfEmpty(false);
    setEnableBirthDateTutor1IfEmpty(false);
  };

  const handleResetStatesIfTutor2EmptyValue = () => {
    setEnableCivilityTutor2IfEmpty(false);
    setEnableFunctionTutor2IfEmpty(false);
    setEnableHighestDegreeTutor2IfEmpty(false);
    setEnableBirthDateTutor2IfEmpty(false);
  };

  const newLocal =
    checkboxSecondTutor &&
    Yup.bool().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value !== false);

  // Yup validation schema
  const Form6Schema = Yup.object().shape({
    takeInfoFrom: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    email: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) =>
        schema.email(t("app.errorMessages.FormatEmailInvalide")).required(t("app.errorMessages.RemplirChamp")),
    }),
    fixPhone: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) =>
        schema
          .test(
            "if-is-aucun",
            t("app.errorMessages.PhoneFormat"),
            (value) => value !== undefined && isValidPhoneNumber(value) !== false,
          )
          .required(t("app.errorMessages.RemplirChamp")),
    }),
    phone: Yup.string().matches(phoneRegExp, t("app.errorMessages.PhoneFormat")),
    lastName: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    firstName: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    function: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) =>
        schema
          .min(2)
          .required(t("app.errorMessages.RemplirChamp"))
          .max(40, `${t("app.errorMessages.Max40")}`),
    }),
    civility: Yup.object().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    birthDate: Yup.date().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(), // 'dateField' devient requis
      otherwise: Yup.date().nullable(),
    }),
    validMa: Yup.bool().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value !== false),
    // socialNumber: Yup.string().when("socialNumber", {
    //   is: (exists) => !!exists,
    //   then: Yup.string()
    //     .matches(/^[12][0-9]{5}([0-9]|[A-Za-z])[0-9]{8}$/, `${t("app.errorMessages.startsWithOneOrTwo")}`)
    //     .test("nir-control-test", t("app.errorMessages.invalidNIR"), (value) => {
    //       if (value?.length < 15) return false;
    //       if (value?.length === 15) {
    //         const temp = value?.split("");
    //         const key = parseInt(temp[13] + temp[14], 10);
    //         if ((temp[6] === "B" || temp[6] === "b") && temp[5] === "2") {
    //           temp[6] = "8";
    //           temp[5] = "1";
    //           const slicedArray = parseInt(temp.slice(0, 13).join(""), 10);
    //           if (key === 97 - (slicedArray % 97)) return true;
    //           return false;
    //         }
    //         if ((temp[6] === "A" || temp[6] === "a") && temp[5] === "2") {
    //           temp[6] = "9";
    //           temp[5] = "1";
    //           const slicedArray = parseInt(temp.slice(0, 13).join(""), 10);
    //           if (key === 97 - (slicedArray % 97)) return true;
    //           return false;
    //         }
    //         const slicedArray = parseInt(temp.slice(0, 13).join(""), 10);
    //         if (key === 97 - (slicedArray % 97)) return true;
    //         return false;
    //       }
    //       return false;
    //     })
    //     .typeError(t("app.errorMessages.RemplirChamp"))
    //     .min(15, t("app.errorMessages.ChiffresRequis15"))
    //     .max(15, t("app.errorMessages.ChiffresRequis15"))
    //     .required(t("app.errorMessages.RemplirChamp")),
    // }),
    highestDegree: Yup.object().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    takeInfoFrom2:
      checkboxSecondTutor &&
      Yup.object().test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    email2: Yup.string().when(["takeInfoFrom2"], {
      is: (takeInfoFrom2) => takeInfoFrom2?.key !== "-Aucun-" && checkboxSecondTutor,
      then: (schema) =>
        schema.email(t("app.errorMessages.FormatEmailInvalide")).required(t("app.errorMessages.RemplirChamp")),
    }),
    fixPhone2: Yup.string().when(["takeInfoFrom2"], {
      is: (takeInfoFrom2) => takeInfoFrom2?.key !== "-Aucun-" && checkboxSecondTutor,
      then: (schema) =>
        schema
          .test(
            "if-is-aucun",
            t("app.errorMessages.PhoneFormat"),
            (value) => value !== undefined && isValidPhoneNumber(value) !== false,
          )
          .required(t("app.errorMessages.RemplirChamp")),
    }),
    phone2: checkboxSecondTutor && Yup.string().matches(phoneRegExp, t("app.errorMessages.PhoneFormat")),
    lastName2: Yup.string().when(["takeInfoFrom2"], {
      is: (takeInfoFrom2) => takeInfoFrom2?.key !== "-Aucun-" && checkboxSecondTutor,
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    firstName2: Yup.string().when(["takeInfoFrom2"], {
      is: (takeInfoFrom2) => takeInfoFrom2?.key !== "-Aucun-" && checkboxSecondTutor,
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    function2: Yup.string().when(["takeInfoFrom2"], {
      is: (takeInfoFrom2) => takeInfoFrom2?.key !== "-Aucun-" && checkboxSecondTutor,
      then: (schema) =>
        schema
          .min(2)
          .required(t("app.errorMessages.RemplirChamp"))
          .max(40, `${t("app.errorMessages.Max40")}`),
    }),
    civility2: Yup.object().when(["takeInfoFrom2"], {
      is: (takeInfoFrom2) => takeInfoFrom2?.key !== "-Aucun-" && checkboxSecondTutor,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    birthDate2: Yup.date().when(["takeInfoFrom2"], {
      is: (takeInfoFrom2) => takeInfoFrom2?.key !== "-Aucun-" && checkboxSecondTutor,
      then: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(), // 'dateField' devient requis
      otherwise: Yup.date().nullable(),
    }),
    validMa2: newLocal,
    // socialNumber2: Yup.string().when("socialNumber2", {
    //   is: (exists) => !!exists && checkboxSecondTutor,
    //   then: Yup.string()
    //     .matches(/^[12][0-9]{5}([0-9]|[A-Za-z])[0-9]{8}$/, `${t("app.errorMessages.startsWithOneOrTwo")}`)
    //     .test("nir-control-test", t("app.errorMessages.invalidNIR"), (value) => {
    //       if (value?.length < 15) return false;
    //       if (value?.length === 15) {
    //         const temp = value?.split("");
    //         const key = parseInt(temp[13] + temp[14], 10);
    //         if ((temp[6] === "B" || temp[6] === "b") && temp[5] === "2") {
    //           temp[6] = "8";
    //           temp[5] = "1";
    //           const slicedArray = parseInt(temp.slice(0, 13).join(""), 10);
    //           if (key === 97 - (slicedArray % 97)) return true;
    //           return false;
    //         }
    //         if ((temp[6] === "A" || temp[6] === "a") && temp[5] === "2") {
    //           temp[6] = "9";
    //           temp[5] = "1";
    //           const slicedArray = parseInt(temp.slice(0, 13).join(""), 10);
    //           if (key === 97 - (slicedArray % 97)) return true;
    //           return false;
    //         }
    //         const slicedArray = parseInt(temp.slice(0, 13).join(""), 10);
    //         if (key === 97 - (slicedArray % 97)) return true;
    //         return false;
    //       }
    //       return false;
    //     })
    //     .typeError(t("app.errorMessages.RemplirChamp"))
    //     .min(15, t("app.errorMessages.ChiffresRequis15"))
    //     .max(15, t("app.errorMessages.ChiffresRequis15"))
    //     .required(t("app.errorMessages.RemplirChamp")),
    // }),
    highestDegree2:
      checkboxSecondTutor &&
      Yup.object().when(["takeInfoFrom2"], {
        is: (takeInfoFrom2) => takeInfoFrom2?.key !== "-Aucun-",
        then: (schema) =>
          schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
      }),
  });

  const onSubmit = async (values) => {
    console.log("CONTRACT VALUE TO SEND 1");

    const valuesCopy = structuredClone(values);

    // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    const dataToSend = {
      status: "En cours",
      company: {
        id: contractData?.company?.id,
        contacts: {
          tutors: [
            {
              copyFrom: valuesCopy?.takeInfoFrom?.key,
              email: valuesCopy.email,
              civility: valuesCopy?.civility?.key,
              name: valuesCopy.lastName,
              firstname: valuesCopy.firstName,
              phones: {
                office: valuesCopy.fixPhone,
                cellular: valuesCopy.phone,
              },
              birthdate: valuesCopy.birthDate ? formatDate(valuesCopy.birthDate) : null,
              function: valuesCopy.function,
              validatedBySkillsOperator: valuesCopy.validMa,
              // socialSecurityNumber: valuesCopy.socialNumber,
              maximalObtainedDiploma: valuesCopy.highestDegree.key,
            },
          ],
        },
      },
    };

    // Second Tutor logic
    if (checkboxSecondTutor) {
      dataToSend.company.contacts.tutors[1] = {
        copyFrom: valuesCopy?.takeInfoFrom2?.key,
        email: valuesCopy.email2,
        civility: valuesCopy?.civility2?.key,
        name: valuesCopy.lastName2,
        firstname: valuesCopy.firstName2,
        phones: {
          office: valuesCopy.fixPhone2,
          cellular: valuesCopy.phone2,
        },
        birthdate: valuesCopy.birthDate2 ? formatDate(valuesCopy.birthDate2) : null,
        function: valuesCopy.function2,
        validatedBySkillsOperator: valuesCopy.validMa2,
        // socialSecurityNumber: valuesCopy.socialNumber2,
        maximalObtainedDiploma: valuesCopy.highestDegree2.key,
      };
    }

    try {
      await dispatch(
        patchContractDataThunk({
          dataToSend,
          id: contractData.id,
          axiosCancelToken,
        }),
      ).unwrap();

      submitNextSectionIfSubmitAllTrue(7);

      if (!submittingAllSections) {
        toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifContactTuteurMaitreApp")} />);
      }
      console.log("Form6 patchContractDataThunk success !", dataToSend);
    } catch (error) {
      cancelSubmitAllSections();
      console.log("Form6 patchContractDataThunk fail !", error);
      toastError(
        <ToastMessageStructure
          firstMessage={t("app.toastMessages.PreContraEntSectionTuteurSubmitError")}
          secondMessage={error.message}
        />,
      );
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit, validateForm, isValid, submitCount } = useFormik({
    initialValues: {
      takeInfoFrom: contractData?.company?.contacts?.tutors[0]?.copyFrom || { ...objAucun },
      email: contractData?.company?.contacts?.tutors[0]?.email || "",
      civility: contractData?.company?.contacts?.tutors[0]?.civility || { ...objAucun },
      birthDate:
        (contractData?.company?.contacts?.tutors[0]?.birthdate &&
          new Date(contractData?.company?.contacts?.tutors[0]?.birthdate)) ||
        null,
      validMa: contractData?.company?.contacts?.tutors[0]?.validatedBySkillsOperator || false,
      lastName: contractData?.company?.contacts?.tutors[0]?.name || "",
      firstName: contractData?.company?.contacts?.tutors[0]?.firstname || "",
      phone: contractData?.company?.contacts?.tutors[0]?.phones?.cellular || "",
      fixPhone: contractData?.company?.contacts?.tutors[0]?.phones?.office || "",
      function: contractData?.company?.contacts?.tutors[0]?.function || "",
      // socialNumber: contractData?.company?.contacts?.tutors[0]?.socialSecurityNumber || "",
      highestDegree: contractData?.company?.contacts?.tutors[0]?.maximalObtainedDiploma || { ...objAucun },
      takeInfoFrom2: contractData?.company?.contacts?.tutors[1]?.copyFrom || { ...objAucun },
      email2: contractData?.company?.contacts?.tutors[1]?.email || "",
      civility2: contractData?.company?.contacts?.tutors[1]?.civility || { ...objAucun },
      birthDate2:
        (contractData?.company?.contacts?.tutors[1]?.birthdate &&
          new Date(contractData?.company?.contacts?.tutors[1]?.birthdate)) ||
        null,
      validMa2: contractData?.company?.contacts?.tutors[1]?.validatedBySkillsOperator || false,
      lastName2: contractData?.company?.contacts?.tutors[1]?.name || "",
      firstName2: contractData?.company?.contacts?.tutors[1]?.firstname || "",
      phone2: contractData?.company?.contacts?.tutors[1]?.phones?.cellular || "",
      fixPhone2: contractData?.company?.contacts?.tutors[1]?.phones?.office || "",
      function2: contractData?.company?.contacts?.tutors[1]?.function || "",
      // socialNumber2: contractData?.company?.contacts?.tutors[1]?.socialSecurityNumber || "",
      highestDegree2: contractData?.company?.contacts?.tutors[1]?.maximalObtainedDiploma || { ...objAucun },
    },
    validationSchema: Form6Schema,
    onSubmit,
  });

  useEffect(() => {
    setInfoFrom(values.takeInfoFrom, true);
    setInfoFrom2(values.takeInfoFrom2, true);
  }, [contractData]);

  const handleBirthDateUpdt = (update, contactType) => {
    if (update) {
      return contractData?.company?.contacts?.tutors[0]?.birthdate
        ? new Date(contractData?.company?.contacts?.tutors[0]?.birthdate)
        : null;
    }
    switch (contactType) {
      case "SIGN":
        return contractData?.company?.contacts?.signatory?.birthdate
          ? new Date(contractData?.company?.contacts?.signatory?.birthdate)
          : null;
      case "TAXE":
        return contractData?.company?.contacts?.taxes?.birthdate
          ? new Date(contractData?.company?.contacts?.taxes?.birthdate)
          : null;
      case "DRAFT":
        return contractData?.contract?.taxes?.contractDrafter?.birthdate
          ? new Date(contractData?.company?.contacts?.contractDrafter?.birthdate)
          : null;
      case "ADMIN":
        return contractData?.contract?.taxes?.administrative?.birthdate
          ? new Date(contractData?.company?.contacts?.administrative?.birthdate)
          : null;
      case "BILL":
        return contractData?.contract?.taxes?.billing?.birthdate
          ? new Date(contractData?.company?.contacts?.billing?.birthdate)
          : null;
      default:
        return null;
    }
  };

  const handleBirthDateUpdt2 = (update, contactType) => {
    if (update) {
      return contractData?.company?.contacts?.tutors[1]?.birthdate
        ? new Date(contractData?.company?.contacts?.tutors[1]?.birthdate)
        : null;
    }
    switch (contactType) {
      case "SIGN":
        return contractData?.company?.contacts?.signatory?.birthdate
          ? new Date(contractData?.company?.contacts?.signatory?.birthdate)
          : null;
      case "TAXE":
        return contractData?.company?.contacts?.taxes?.birthdate
          ? new Date(contractData?.company?.contacts?.taxes?.birthdate)
          : null;
      case "DRAFT":
        return contractData?.contract?.taxes?.contractDrafter?.birthdate
          ? new Date(contractData?.company?.contacts?.contractDrafter?.birthdate)
          : null;
      case "ADMIN":
        return contractData?.contract?.taxes?.administrative?.birthdate
          ? new Date(contractData?.company?.contacts?.administrative?.birthdate)
          : null;
      case "BILL":
        return contractData?.contract?.taxes?.billing?.birthdate
          ? new Date(contractData?.company?.contacts?.billing?.birthdate)
          : null;
      default:
        return null;
    }
  };

  const setInfoFrom = (value, update) => {
    switch (value?.key) {
      case "Contact signataire":
        setFieldValue(
          "email",
          contractData?.company?.contacts?.signatory?.email ? contractData?.company?.contacts?.signatory?.email : "",
        );
        setFieldValue(
          "civility",
          contractData?.company?.contacts?.signatory?.civility
            ? contractData?.company?.contacts?.signatory?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          contractData?.company?.contacts?.signatory?.name ? contractData?.company?.contacts?.signatory?.name : "",
        );
        setFieldValue(
          "firstName",
          contractData?.company?.contacts?.signatory?.firstname
            ? contractData?.company?.contacts?.signatory?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          contractData?.company?.contacts?.signatory?.phones?.cellular
            ? contractData?.company?.contacts?.signatory?.phones?.cellular
            : "",
        );
        setFieldValue("fixPhone", contractData?.company?.contacts?.signatory?.phones?.office);
        setFieldValue(
          "function",
          contractData?.company?.contacts?.signatory?.function
            ? contractData?.company?.contacts?.signatory?.function
            : "",
        );
        setFieldValue("birthDate", handleBirthDateUpdt(update, "SIGN"));
        setFieldValue(
          "highestDegree",
          contractData?.company?.contacts?.tutors[0]?.maximalObtainedDiploma || { ...objAucun },
        );
        setFromTakeInfo(true);
        break;
      case "Contact établissement du contrat":
        setFieldValue(
          "email",
          contractData?.company?.contacts?.contractDrafter?.email
            ? contractData?.company?.contacts?.contractDrafter?.email
            : "",
        );
        setFieldValue(
          "civility",
          contractData?.company?.contacts?.contractDrafter?.civility
            ? contractData?.company?.contacts?.contractDrafter?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          contractData?.company?.contacts?.contractDrafter?.name
            ? contractData?.company?.contacts?.contractDrafter?.name
            : "",
        );
        setFieldValue(
          "firstName",
          contractData?.company?.contacts?.contractDrafter?.firstname
            ? contractData?.company?.contacts?.contractDrafter?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          contractData?.company?.contacts?.contractDrafter?.phones?.cellular
            ? contractData?.company?.contacts?.contractDrafter?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          contractData?.company?.contacts?.contractDrafter?.phones?.office
            ? contractData?.company?.contacts?.contractDrafter?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          contractData?.company?.contacts?.contractDrafter?.function
            ? contractData?.company?.contacts?.contractDrafter?.function
            : "",
        );
        setFieldValue("birthDate", handleBirthDateUpdt(update, "DRAFT"));
        setFieldValue(
          "highestDegree",
          contractData?.company?.contacts?.tutors[0]?.maximalObtainedDiploma || { ...objAucun },
        );
        setFromTakeInfo(true);
        break;
      case "Contact administratif":
        setFieldValue(
          "email",
          contractData?.company?.contacts?.administrative?.email
            ? contractData?.company?.contacts?.administrative?.email
            : "",
        );
        setFieldValue(
          "civility",
          contractData?.company?.contacts?.administrative?.civility
            ? contractData?.company?.contacts?.administrative?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          contractData?.company?.contacts?.administrative?.name
            ? contractData?.company?.contacts?.administrative?.name
            : "",
        );
        setFieldValue(
          "firstName",
          contractData?.company?.contacts?.administrative?.firstname
            ? contractData?.company?.contacts?.administrative?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          contractData?.company?.contacts?.administrative?.phones?.cellular
            ? contractData?.company?.contacts?.administrative?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          contractData?.company?.contacts?.administrative?.phones?.office
            ? contractData?.company?.contacts?.administrative?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          contractData?.company?.contacts?.administrative?.function
            ? contractData?.company?.contacts?.administrative?.function
            : "",
        );
        setFieldValue("birthDate", handleBirthDateUpdt(update, "ADMIN"));
        setFieldValue(
          "highestDegree",
          contractData?.company?.contacts?.tutors[0]?.maximalObtainedDiploma || { ...objAucun },
        );
        setFromTakeInfo(true);
        break;
      case "Contact facturation":
        setFieldValue(
          "email",
          contractData?.company?.contacts?.billing?.email ? contractData?.company?.contacts?.billing?.email : "",
        );
        setFieldValue(
          "civility",
          contractData?.company?.contacts?.billing?.civility
            ? contractData?.company?.contacts?.billing?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          contractData?.company?.contacts?.billing?.name ? contractData?.company?.contacts?.billing?.name : "",
        );
        setFieldValue(
          "firstName",
          contractData?.company?.contacts?.billing?.firstname
            ? contractData?.company?.contacts?.billing?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          contractData?.company?.contacts?.billing?.phones?.cellular
            ? contractData?.company?.contacts?.billing?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          contractData?.company?.contacts?.billing?.phones?.office
            ? contractData?.company?.contacts?.billing?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          contractData?.company?.contacts?.billing?.function ? contractData?.company?.contacts?.billing?.function : "",
        );
        setFieldValue("birthDate", handleBirthDateUpdt(update, "BILL"));
        setFieldValue(
          "highestDegree",
          contractData?.company?.contacts?.tutors[0]?.maximalObtainedDiploma || { ...objAucun },
        );
        setFromTakeInfo(true);
        break;
      case "Aucun":
        if (!update) {
          setFieldValue("email", "");
          setFieldValue("civility", { ...objAucun });
          setFieldValue("lastName", "");
          setFieldValue("firstName", "");
          setFieldValue("phone", "");
          setFieldValue("fixPhone", "");
          setFieldValue("function", "");
          setFieldValue("birthDate", null);
          setFieldValue("highestDegree", { ...objAucun });
          setFromTakeInfo(false);

          // TODO   ->   handle user from other company for phone & function
        } else {
          setFieldValue(
            "email",
            contractData?.company?.contacts?.tutors[0]?.email ? contractData?.company?.contacts?.tutors[0]?.email : "",
          );
          setFieldValue(
            "civility",
            contractData?.company?.contacts?.tutors[0]?.civility
              ? contractData?.company?.contacts?.tutors[0]?.civility
              : { ...objAucun },
          );
          setFieldValue(
            "lastName",
            contractData?.company?.contacts?.tutors[0]?.name ? contractData?.company?.contacts?.tutors[0]?.name : "",
          );
          setFieldValue(
            "firstName",
            contractData?.company?.contacts?.tutors[0]?.firstname
              ? contractData?.company?.contacts?.tutors[0]?.firstname
              : "",
          );
          setFieldValue(
            "phone",
            contractData?.company?.contacts?.tutors[0]?.phones?.cellular
              ? contractData?.company?.contacts?.tutors[0]?.phones?.cellular
              : "",
          );
          setFieldValue(
            "fixPhone",
            contractData?.company?.contacts?.tutors[0]?.phones?.office
              ? contractData?.company?.contacts?.tutors[0]?.phones?.office
              : "",
          );
          setFieldValue(
            "function",
            contractData?.company?.contacts?.tutors[0]?.function
              ? contractData?.company?.contacts?.tutors[0]?.function
              : "",
          );
          setFieldValue(
            "birthDate",
            contractData?.company?.contacts?.tutors[0]?.birthdate
              ? new Date(contractData?.company?.contacts?.tutors[0]?.birthdate)
              : null,
          );
          setFieldValue(
            "highestDegree",
            contractData?.company?.contacts?.tutors[0]?.maximalObtainedDiploma || { ...objAucun },
          );
          const tmpFromTake = fromTakeInfo;
          setFromTakeInfo(tmpFromTake);

          // TODO   ->   handle user from other company for phone & function
        }

        break;
      case "-Aucun-":
        setFieldValue("email", "");
        setFieldValue("civility", { ...objAucun });
        setFieldValue("lastName", "");
        setFieldValue("firstName", "");
        setFieldValue("phone", "");
        setFieldValue("fixPhone", "");
        setFieldValue("function", "");
        setFieldValue("birthDate", null);
        setFieldValue("highestDegree", { ...objAucun });
        setFromTakeInfo(false);
        break;
      default:
        setFieldValue("email", "");
        setFieldValue("civility", { ...objAucun });
        setFieldValue("lastName", "");
        setFieldValue("firstName", "");
        setFieldValue("phone", "");
        setFieldValue("fixPhone", "");
        setFieldValue("function", "");
        setFieldValue("birthDate", null);
        setFieldValue("highestDegree", { ...objAucun });
        setFromTakeInfo(false);
        break;
    }
  };

  const setInfoFrom2 = (value, update) => {
    console.log("SET INFO 2", value);
    switch (value?.key) {
      case "Contact signataire":
        setFieldValue(
          "email2",
          contractData?.company?.contacts?.signatory?.email ? contractData?.company?.contacts?.signatory?.email : "",
        );
        setFieldValue(
          "civility2",
          contractData?.company?.contacts?.signatory?.civility
            ? contractData?.company?.contacts?.signatory?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName2",
          contractData?.company?.contacts?.signatory?.name ? contractData?.company?.contacts?.signatory?.name : "",
        );
        setFieldValue(
          "firstName2",
          contractData?.company?.contacts?.signatory?.firstname
            ? contractData?.company?.contacts?.signatory?.firstname
            : "",
        );
        setFieldValue(
          "phone2",
          contractData?.company?.contacts?.signatory?.phones?.cellular
            ? contractData?.company?.contacts?.signatory?.phones?.cellular
            : "",
        );
        setFieldValue("fixPhone2", contractData?.company?.contacts?.signatory?.phones?.office);
        setFieldValue(
          "function2",
          contractData?.company?.contacts?.signatory?.function
            ? contractData?.company?.contacts?.signatory?.function
            : "",
        );
        setFieldValue("birthDate2", handleBirthDateUpdt2(update, "SIGN"));
        setFieldValue(
          "highestDegree2",
          contractData?.company?.contacts?.tutors[1]?.maximalObtainedDiploma || { ...objAucun },
        );
        setFromTakeInfo2(true);
        break;
      case "Contact établissement du contrat":
        setFieldValue(
          "email2",
          contractData?.company?.contacts?.contractDrafter?.email
            ? contractData?.company?.contacts?.contractDrafter?.email
            : "",
        );
        setFieldValue(
          "civility2",
          contractData?.company?.contacts?.contractDrafter?.civility
            ? contractData?.company?.contacts?.contractDrafter?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName2",
          contractData?.company?.contacts?.contractDrafter?.name
            ? contractData?.company?.contacts?.contractDrafter?.name
            : "",
        );
        setFieldValue(
          "firstName2",
          contractData?.company?.contacts?.contractDrafter?.firstname
            ? contractData?.company?.contacts?.contractDrafter?.firstname
            : "",
        );
        setFieldValue(
          "phone2",
          contractData?.company?.contacts?.contractDrafter?.phones?.cellular
            ? contractData?.company?.contacts?.contractDrafter?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone2",
          contractData?.company?.contacts?.contractDrafter?.phones?.office
            ? contractData?.company?.contacts?.contractDrafter?.phones?.office
            : "",
        );
        setFieldValue(
          "function2",
          contractData?.company?.contacts?.contractDrafter?.function
            ? contractData?.company?.contacts?.contractDrafter?.function
            : "",
        );
        setFieldValue("birthDate2", handleBirthDateUpdt2(update, "DRAFT"));
        setFieldValue(
          "highestDegree2",
          contractData?.company?.contacts?.tutors[1]?.maximalObtainedDiploma || { ...objAucun },
        );
        setFromTakeInfo2(true);
        break;
      case "Contact administratif":
        setFieldValue(
          "email2",
          contractData?.company?.contacts?.administrative?.email
            ? contractData?.company?.contacts?.administrative?.email
            : "",
        );
        setFieldValue(
          "civility2",
          contractData?.company?.contacts?.administrative?.civility
            ? contractData?.company?.contacts?.administrative?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName2",
          contractData?.company?.contacts?.administrative?.name
            ? contractData?.company?.contacts?.administrative?.name
            : "",
        );
        setFieldValue(
          "firstName2",
          contractData?.company?.contacts?.administrative?.firstname
            ? contractData?.company?.contacts?.administrative?.firstname
            : "",
        );
        setFieldValue(
          "phone2",
          contractData?.company?.contacts?.administrative?.phones?.cellular
            ? contractData?.company?.contacts?.administrative?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone2",
          contractData?.company?.contacts?.administrative?.phones?.office
            ? contractData?.company?.contacts?.administrative?.phones?.office
            : "",
        );
        setFieldValue(
          "function2",
          contractData?.company?.contacts?.administrative?.function
            ? contractData?.company?.contacts?.administrative?.function
            : "",
        );
        setFieldValue("birthDate2", handleBirthDateUpdt2(update, "ADMIN"));
        setFieldValue(
          "highestDegree2",
          contractData?.company?.contacts?.tutors[1]?.maximalObtainedDiploma || { ...objAucun },
        );
        setFromTakeInfo2(true);
        break;
      case "Contact facturation":
        setFieldValue(
          "email2",
          contractData?.company?.contacts?.billing?.email ? contractData?.company?.contacts?.billing?.email : "",
        );
        setFieldValue(
          "civility2",
          contractData?.company?.contacts?.billing?.civility
            ? contractData?.company?.contacts?.billing?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName2",
          contractData?.company?.contacts?.billing?.name ? contractData?.company?.contacts?.billing?.name : "",
        );
        setFieldValue(
          "firstName2",
          contractData?.company?.contacts?.billing?.firstname
            ? contractData?.company?.contacts?.billing?.firstname
            : "",
        );
        setFieldValue(
          "phone2",
          contractData?.company?.contacts?.billing?.phones?.cellular
            ? contractData?.company?.contacts?.billing?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone2",
          contractData?.company?.contacts?.billing?.phones?.office
            ? contractData?.company?.contacts?.billing?.phones?.office
            : "",
        );
        setFieldValue(
          "function2",
          contractData?.company?.contacts?.billing?.function ? contractData?.company?.contacts?.billing?.function : "",
        );
        setFieldValue("birthDate2", handleBirthDateUpdt2(update, "BILL"));
        setFieldValue(
          "highestDegree2",
          contractData?.company?.contacts?.tutors[1]?.maximalObtainedDiploma || { ...objAucun },
        );
        setFromTakeInfo2(true);
        break;
      case "Aucun":
        if (!update) {
          setFieldValue("email2", "");
          setFieldValue("civility2", { ...objAucun });
          setFieldValue("lastName2", "");
          setFieldValue("firstName2", "");
          setFieldValue("phone2", "");
          setFieldValue("fixPhone2", "");
          setFieldValue("function2", "");
          setFieldValue("birthDate2", null);
          setFieldValue("highestDegree2", { ...objAucun });
          setFromTakeInfo2(false);

          // TODO   ->   handle user from other company for phone & function
        } else {
          setFieldValue(
            "email2",
            contractData?.company?.contacts?.tutors[1]?.email ? contractData?.company?.contacts?.tutors[1]?.email : "",
          );
          setFieldValue(
            "civility2",
            contractData?.company?.contacts?.tutors[1]?.civility
              ? contractData?.company?.contacts?.tutors[1]?.civility
              : { ...objAucun },
          );
          setFieldValue(
            "lastName2",
            contractData?.company?.contacts?.tutors[1]?.name ? contractData?.company?.contacts?.tutors[1]?.name : "",
          );
          setFieldValue(
            "firstName2",
            contractData?.company?.contacts?.tutors[1]?.firstname
              ? contractData?.company?.contacts?.tutors[1]?.firstname
              : "",
          );
          setFieldValue(
            "phone2",
            contractData?.company?.contacts?.tutors[1]?.phones?.cellular
              ? contractData?.company?.contacts?.tutors[1]?.phones?.cellular
              : "",
          );
          setFieldValue(
            "fixPhone2",
            contractData?.company?.contacts?.tutors[1]?.phones?.office
              ? contractData?.company?.contacts?.tutors[1]?.phones?.office
              : "",
          );
          setFieldValue(
            "function2",
            contractData?.company?.contacts?.tutors[1]?.function
              ? contractData?.company?.contacts?.tutors[1]?.function
              : "",
          );
          setFieldValue(
            "birthDate2",
            contractData?.company?.contacts?.tutors[1]?.birthdate
              ? new Date(contractData?.company?.contacts?.tutors[1]?.birthdate)
              : null,
          );
          setFieldValue(
            "highestDegree2",
            contractData?.company?.contacts?.tutors[1]?.maximalObtainedDiploma || { ...objAucun },
          );
          const tmpFromTake = fromTakeInfo2;
          setFromTakeInfo2(tmpFromTake);

          // TODO   ->   handle user from other company for phone & function
        }

        break;
      case "-Aucun-":
        setFieldValue("email2", "");
        setFieldValue("civility2", { ...objAucun });
        setFieldValue("lastName2", "");
        setFieldValue("firstName2", "");
        setFieldValue("phone2", "");
        setFieldValue("fixPhone2", "");
        setFieldValue("function2", "");
        setFieldValue("birthDate2", null);
        setFieldValue("highestDegree2", { ...objAucun });
        setFromTakeInfo2(false);
        break;
      default:
        setFieldValue("email2", "");
        setFieldValue("civility2", { ...objAucun });
        setFieldValue("lastName2", "");
        setFieldValue("firstName2", "");
        setFieldValue("phone2", "");
        setFieldValue("fixPhone2", "");
        setFieldValue("function2", "");
        setFieldValue("birthDate2", null);
        setFieldValue("highestDegree2", { ...objAucun });
        setFromTakeInfo2(false);
        break;
    }
  };

  const displayUserData = () => {
    if (FORM === formStep) {
      setFieldValue("lastName", employeeDetails[0]?.name ? employeeDetails[0]?.name : "");
      setFieldValue("firstName", employeeDetails[0]?.firstname ? employeeDetails[0]?.firstname : "");
      setFieldValue("civility", employeeDetails[0]?.civility ? employeeDetails[0]?.civility : { ...objAucun });
      setFieldValue("fixPhone", employeeDetails[0]?.phones?.office ? employeeDetails[0]?.phones?.office : "");
      setFieldValue("phone", employeeDetails[0]?.phones?.cellular ? employeeDetails[0]?.phones?.cellular : "");
      setFieldValue("function", employeeDetails[0]?.function ? employeeDetails[0]?.function : "");
      setFieldValue("birthDate", employeeDetails[0]?.birthdate ? new Date(employeeDetails[0]?.birthdate) : null);
      setFormStep("");
    }
  };

  const displayUserData2 = () => {
    if (FORM === formStep) {
      setFieldValue("lastName2", employeeDetails2[0]?.name ? employeeDetails2[0]?.name : "");
      setFieldValue("firstName2", employeeDetails2[0]?.firstname ? employeeDetails2[0]?.firstname : "");
      setFieldValue("civility2", employeeDetails2[0]?.civility ? employeeDetails2[0]?.civility : { ...objAucun });
      setFieldValue("fixPhone2", employeeDetails2[0]?.phones?.office ? employeeDetails2[0]?.phones?.office : "");
      setFieldValue("phone2", employeeDetails2[0]?.phones?.cellular ? employeeDetails2[0]?.phones?.cellular : "");
      setFieldValue("function2", employeeDetails2[0]?.function ? employeeDetails2[0]?.function : "");
      setFieldValue("birthDate2", employeeDetails2[0]?.birthdate ? new Date(employeeDetails2[0]?.birthdate) : null);
      setFormStep("");
    }
  };

  const displayDefaultData = () => {
    if (FORM === formStep) {
      setFieldValue("civility", { ...objAucun });
      setFieldValue("lastName", "");
      setFieldValue("firstName", "");
      setFieldValue("fixPhone", "");
      setFieldValue("phone", "");
      setFieldValue("function", "");
      setFieldValue("birthDate", null);
      setFieldValue("highestDegree", { ...objAucun });
      setFormStep("");
    }
  };

  const displayDefaultData2 = () => {
    if (FORM === formStep) {
      setFieldValue("civility2", { ...objAucun });
      setFieldValue("lastName2", "");
      setFieldValue("firstName2", "");
      setFieldValue("fixPhone2", "");
      setFieldValue("phone2", "");
      setFieldValue("function2", "");
      setFieldValue("birthDate2", null);
      setFieldValue("highestDegree2", { ...objAucun });
      setFormStep("");
    }
  };

  const handleEnableOnMount = () => {
    if (values?.takeInfoFrom?.key === "Aucun" || values?.takeInfoFrom?.key === "-Aucun-") {
      setFromTakeInfo(false);
    } else if (values?.takeInfoFrom?.key !== "Aucun" || values?.takeInfoFrom?.key !== "-Aucun-") {
      setFromTakeInfo(true);
    }
  };

  const handleEnableOnMount2 = () => {
    if (values?.takeInfoFrom2?.key === "Aucun" || values?.takeInfoFrom2?.key === "-Aucun-") {
      setFromTakeInfo2(false);
    } else if (values?.takeInfoFrom2?.key !== "Aucun" || values?.takeInfoFrom2?.key !== "-Aucun-") {
      setFromTakeInfo2(true);
    }
  };

  useEffect(() => {
    if (values?.takeInfoFrom?.key === "-Aucun-" || values?.takeInfoFrom?.key === "Aucun") {
      if (!errors.email && values.email.length > 1) {
        if (timedOutRef.current) clearTimeout(timedOutRef.current);
        timedOutRef.current = setTimeout(() => {
          (async () => {
            try {
              await dispatch(
                getEmployeesThunk({
                  email: values.email,
                  axiosCancelToken,
                }),
              ).unwrap();
              handleResetStatesIfTutor1EmptyValue();
              console.log("get success Employees !");
            } catch (error) {
              handleResetStatesIfTutor1EmptyValue();
              if (isOpen) {
                console.log("get fail Employees !", error.message);
                toastError(
                  <ToastMessageStructure
                    firstMessage={t("app.toastMessages.PreContraEntGetEmployeeError")}
                    secondMessage={error.message}
                  />,
                );
              }
            }
          })();
        }, "500");
      } else {
        setFieldValue("civility", { ...objAucun });
        setFieldValue("lastName", "");
        setFieldValue("firstName", "");
        setFieldValue("fixPhone", "");
        setFieldValue("phone", "");
        setFieldValue("function", "");
        setFieldValue("birthDate", null);
        setFieldValue("highestDegree", { ...objAucun });
      }
    }
  }, [errors.email, values.email]);

  useEffect(() => {
    if (values?.takeInfoFrom2?.key === "-Aucun-" || values?.takeInfoFrom2?.key === "Aucun") {
      if (!errors.email2 && values.email2.length > 1) {
        if (timedOutRef2.current) clearTimeout(timedOutRef2.current);
        timedOutRef2.current = setTimeout(() => {
          (async () => {
            try {
              await dispatch(
                getEmployeesThunk2({
                  email: values.email2,
                  axiosCancelToken,
                }),
              ).unwrap();
              handleResetStatesIfTutor2EmptyValue();
              console.log("get success Employees2 !");
            } catch (error) {
              handleResetStatesIfTutor2EmptyValue();
              console.log("get fail Employees2 !", error.message);
              if (isOpen) {
                toastError(
                  <ToastMessageStructure
                    firstMessage={t("app.toastMessages.PreContraEntGetEmployeeError")}
                    secondMessage={error.message}
                  />,
                );
              }
            }
          })();
        }, "500");
      } else {
        setFieldValue("civility2", { ...objAucun });
        setFieldValue("lastName2", "");
        setFieldValue("firstName2", "");
        setFieldValue("fixPhone2", "");
        setFieldValue("phone2", "");
        setFieldValue("function2", "");
        setFieldValue("birthDate2", null);
        setFieldValue("highestDegree2", { ...objAucun });
      }
    }
  }, [errors.email2, values.email2]);

  console.log("values?.takeInfoFrom", values?.takeInfoFrom);
  console.log("values?.takeInfoFrom2", values?.takeInfoFrom2);

  useEffect(() => {
    if (values) handleEnableOnMount(contractData?.company?.contacts?.tutors[0]);
    handleEnableOnMount2(contractData?.company?.contacts?.tutors[1]);
  }, []);

  useEffect(() => {
    if (employeeDetails) {
      if (employeeDetails.length > 0 && formStep === FORM) {
        displayUserData();
      } else displayDefaultData();
    }
  }, [employeeDetails]);

  useEffect(() => {
    if (employeeDetails2) {
      if (employeeDetails2.length > 0 && formStep === FORM) {
        displayUserData2();
      } else displayDefaultData2();
    }
  }, [employeeDetails2]);

  useEffect(() => {
    if (contractData) {
      if (contractData?.company?.contacts?.tutors[1]) {
        setCheckboxSecondTutor(true);
      }
    }
  }, [contractData]);

  // useLayoutEffect to force Formik's isValid check, the first time the component is mounted
  useLayoutEffect(() => {
    validateForm();
  }, [values.fixPhone, values.fixPhone2]);

  // useLayoutEffect to control the color of the DropdownSection circle.
  useLayoutEffect(() => {
    if (isValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [isValid]);

  // useLayoutEffect to manage the trigger at the right time for sending this form, during global submission.
  useLayoutEffect(() => {
    const thisFormNumber = 6;

    if (isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
    } else if (!isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
      cancelSubmitAllSections();
      toastError(
        <ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionTuteurNonRemplieError")} />,
      );
    }
  }, [isValid, submittingAllSections, currentSectionBeingSubmitted]);

  const isMailDisabled = !(values.takeInfoFrom?.key === "Aucun");
  const isMail2Disabled = !(checkboxSecondTutor && values.takeInfoFrom2?.key === "Aucun");
  const isDisabled = !(values.takeInfoFrom?.key === "Aucun" && values.email && values.email.length > 0);
  const isDisabled2 = !(
    checkboxSecondTutor &&
    values.takeInfoFrom2?.key === "Aucun" &&
    values.email2 &&
    values.email2.length > 0
  );

  useEffect(() => {
    if (values?.takeInfoFrom?.key !== "-Aucun-" && values.email) {
      if (values?.civility?.key === "-Aucun-") {
        setEnableCivilityTutor1IfEmpty(true);
      }
      if (!values?.function) {
        setEnableFunctionTutor1IfEmpty(true);
      }
      if (!values?.birthDate) {
        setEnableBirthDateTutor1IfEmpty(true);
      }
      if (values?.highestDegree?.key === "-Aucun-") {
        setEnableHighestDegreeTutor1IfEmpty(true);
      }
    } else {
      handleResetStatesIfTutor1EmptyValue();
    }

    if (values?.takeInfoFrom2?.key !== "-Aucun-" && values.email2) {
      if (values?.civility2?.key === "-Aucun-") {
        setEnableCivilityTutor2IfEmpty(true);
      }
      if (!values?.function2) {
        setEnableFunctionTutor2IfEmpty(true);
      }
      if (!values?.birthDate2) {
        setEnableBirthDateTutor2IfEmpty(true);
      }
      if (values?.highestDegree2?.key === "-Aucun-") {
        setEnableHighestDegreeTutor2IfEmpty(true);
      }
    } else {
      handleResetStatesIfTutor2EmptyValue();
    }
  }, [values]);

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <SubTitleInForm additionalClassNames={styles.subtitle_in_form}>
          {t("app.suivezVosContratsPartner.TuteurEtMaitreDapprentissage1")}
        </SubTitleInForm>
        <div className={styles.wrapper}>
          <FormDropdownField
            id="takeInfoFrom"
            label={t("app.suivezVosContratsPartner.ReprendreInfo")}
            additionalClassNames={styles.formGroup}
            dataKey="key"
            textField="label"
            disabled={false}
            errors={errors.takeInfoFrom}
            touched={touched.takeInfoFrom}
            data={[{ ...objAucun }, ...(referencialData?.COMPANY_CONTACT_TYPE_TO_COPY_FOR_TUTOR_CONTACT || [])]}
            values={values.takeInfoFrom}
            onChange={(e) => {
              setInfoFrom(e, false);
              setFieldValue("takeInfoFrom", e);
            }}
          />
        </div>
        <SubTitleInForm additionalClassNames={styles.subtitle_in_form}>
          {t("app.suivezVosContratsPartner.CoordonneesTitle")}
        </SubTitleInForm>
        <div className={styles.wrapper}>
          <FormInputField
            id="email"
            label={t("app.suivezVosContratsPartner.EmailDuContact")}
            errors={errors.email}
            values={values.email}
            touched={touched.email}
            loading={isEmployeeDataPending}
            disabled={isMailDisabled}
            onChange={(e) => {
              setFieldValue("email", e.target.value);
              setFormStep(FORM);
            }}
          >
            <p className={styles.email_text}>{t("app.suivezVosContratsPartner.EmailInfo")}</p>
          </FormInputField>
          <FormPhoneField
            id="fixPhone"
            label={t("app.suivezVosContratsPartner.Telephone")}
            errors={errors.fixPhone}
            values={values.fixPhone}
            touched={submitCount > 0}
            disabled={isDisabled}
            onChange={(e) => {
              setFieldValue("fixPhone", e);
            }}
          />
          <FormInputField
            id="phone"
            label={t("app.suivezVosContratsPartner.Portable")}
            errors={errors.phone}
            values={values.phone}
            touched={touched.phone}
            disabled={isDisabled}
            onChange={(e) => {
              setFieldValue("phone", e.target.value);
            }}
          />
        </div>
        <SubTitleInForm additionalClassNames={styles.subtitle_in_form}>
          {t("app.suivezVosContratsPartner.IdentiteTitle")}
        </SubTitleInForm>
        <div className={styles.wrapper}>
          <FormInputField
            id="lastName"
            label={t("app.suivezVosContratsPartner.Nom")}
            errors={errors.lastName}
            values={values.lastName}
            touched={touched.lastName}
            disabled={isDisabled}
            onChange={(e) => {
              setFieldValue("lastName", e.target.value);
            }}
          />
          <FormInputField
            id="firstName"
            label={t("app.suivezVosContratsPartner.Prenom")}
            errors={errors.firstName}
            values={values.firstName}
            touched={touched.firstName}
            disabled={isDisabled}
            onChange={(e) => {
              setFieldValue("firstName", e.target.value);
            }}
          />
          <FormDateField
            label={t("app.suivezVosContratsPartner.DateDeNaissance")}
            errors={errors.birthDate}
            touched={touched.birthDate}
            disabled={isDisabled && !enableBirthDateTutor1IfEmpty && true}
            id="birthDate"
            values={values.birthDate}
            onChange={(value) => setFieldValue("birthDate", value)}
          />
        </div>
        <div className={styles.wrapper}>
          <FormDropdownField
            label={t("app.suivezVosContratsPartner.Civilite")}
            id="civility"
            errors={errors.civility}
            touched={touched.civility}
            dataKey="key"
            textField="label"
            data={[{ ...objAucun }, ...(referencialData?.CIVILITY || [])]}
            disabled={isDisabled && !enableCivilityTutor1IfEmpty && true}
            values={values.civility}
            onChange={(newValue) => setFieldValue("civility", newValue)}
          />
          {/* <FormInputField
            label={t("app.suivezVosContratsPartner.SocialSecurityNumber")}
            errors={errors.socialNumber}
            touched={touched.socialNumber}
            id="socialNumber"
            type="text"
            min="0"
            values={values.socialNumber}
            onChange={(e) => setFieldValue("socialNumber", e.target.value)}
          /> */}
        </div>

        <div className={styles.wrapper}>
          <FormInputField
            label={t("app.suivezVosContratsPartner.Fonction")}
            errors={errors.function}
            touched={touched.function}
            id="function"
            type="text"
            disabled={isDisabled && !enableFunctionTutor1IfEmpty && true}
            values={values.function}
            onChange={(e) => setFieldValue("function", e.target.value)}
          />
          <FormDropdownField
            label={t("app.suivezVosContratsPartner.HighestDegree")}
            errors={errors.highestDegree}
            touched={touched.highestDegree}
            id="highestDegree"
            dataKey="key"
            textField="label"
            data={[{ ...objAucun }, ...(referencialData?.APPRENTICESHIP_STUDENT_DIPLOMA || [])]}
            values={values.highestDegree}
            // disabled={isDisabled && !enableHighestDegreeTutor1IfEmpty && true}
            onChange={(newValue) => setFieldValue("highestDegree", newValue)}
          />
        </div>
        <div className={styles.checkbox_container}>
          <label className={styles.checkbox_label} htmlFor="validMa">
            {t("app.suivezVosContratsPartner.Habilitation")}
          </label>
          <FormCustomCheckBoxField
            text={(
              <>
                {t("app.suivezVosContratsPartner.MaitreDapprentissageHabilite.partOne")}
                <a href="https://travail-emploi.gouv.fr/IMG/pdf/apprentissage-infographie-role_du_maitre.pdf">
                  {t("app.suivezVosContratsPartner.MaitreDapprentissageHabilite.link")}
                </a>
                {t("app.suivezVosContratsPartner.MaitreDapprentissageHabilite.partTwo")}
              </>
            )}
            id="validMa"
            checkboxValue={values.validMa}
            handleChange={(e) => setFieldValue("validMa", e)}
            error={errors.validMa}
            touched={touched.validMa}
          />
        </div>
        <SubTitleInForm additionalClassNames={styles.subtitle_in_form}>
          {t("app.suivezVosContratsPartner.TuteurEtMaitreDapprentissage2")}
        </SubTitleInForm>
        <div className={styles.checkbox_container}>
          <label className={styles.checkbox_label} htmlFor="addSecondTutorCheckbox">
            {t("app.suivezVosContratsPartner.SecondTutorCheckbox")}
          </label>
          <FormCustomCheckBoxField
            id="addSecondTutorCheckbox"
            checkboxValue={checkboxSecondTutor}
            handleChange={(e) => setCheckboxSecondTutor(e)}
            text={t("app.suivezVosContratsPartner.AjoutTuteur")}
          />
        </div>
        {checkboxSecondTutor && (
          <>
            <div className={styles.wrapper}>
              <FormDropdownField
                label={t("app.suivezVosContratsPartner.ReprendreInfo")}
                errors={errors.takeInfoFrom2}
                touched={touched.takeInfoFrom2}
                id="takeInfoFrom2"
                dataKey="key"
                textField="label"
                disabled={false}
                data={[{ ...objAucun }, ...(referencialData?.COMPANY_CONTACT_TYPE_TO_COPY_FOR_TUTOR_CONTACT || [])]}
                values={values.takeInfoFrom2}
                onChange={(e) => {
                  setInfoFrom2(e, false);
                  setFieldValue("takeInfoFrom2", e);
                }}
              />
            </div>
            <SubTitleInForm additionalClassNames={styles.subtitle_in_form}>
              {t("app.suivezVosContratsPartner.CoordonneesTitle")}
            </SubTitleInForm>
            <div className={styles.wrapper}>
              <FormInputField
                label={t("app.suivezVosContratsPartner.EmailDuContact")}
                id="email2"
                errors={errors.email2}
                values={values.email2}
                touched={touched.email2}
                loading={isEmployeeDataPending}
                disabled={isMail2Disabled}
                additionalClassNames={styles.formGroup}
                onChange={(e) => {
                  setFieldValue("email2", e.target.value);
                  setFormStep(FORM);
                }}
              >
                <p className={styles.email_text}>{t("app.suivezVosContratsPartner.EmailInfo")}</p>
              </FormInputField>
              <FormPhoneField
                id="fixPhone2"
                label={t("app.suivezVosContratsPartner.Telephone")}
                errors={errors.fixPhone2}
                values={values.fixPhone2}
                touched={submitCount > 0}
                disabled={isDisabled2}
                onChange={(e) => {
                  setFieldValue("fixPhone2", e);
                }}
              />
              <FormInputField
                label={t("app.suivezVosContratsPartner.Portable")}
                errors={errors.phone2}
                touched={touched.phone2}
                id="phone2"
                name="phone2"
                type="text"
                disabled={isDisabled2}
                values={values.phone2}
                onChange={(e) => setFieldValue("phone2", e.target.value)}
              />
            </div>
            <SubTitleInForm additionalClassNames={styles.subtitle_in_form}>
              {t("app.suivezVosContratsPartner.IdentiteTitle")}
            </SubTitleInForm>
            <div className={styles.wrapper}>
              <FormInputField
                label={t("app.suivezVosContratsPartner.Nom")}
                errors={errors.lastName2}
                touched={touched.lastName2}
                id="lastName2"
                type="text"
                disabled={isDisabled2}
                values={values.lastName2}
                onChange={(e) => setFieldValue("lastName2", e.target.value)}
              />
              <FormInputField
                label={t("app.suivezVosContratsPartner.Prenom")}
                errors={errors.firstName2}
                touched={touched.firstName2}
                id="firstName2"
                type="text"
                disabled={isDisabled2}
                values={values.firstName2}
                onChange={(e) => setFieldValue("firstName2", e.target.value)}
              />
              <FormDateField
                label={t("app.suivezVosContratsPartner.DateDeNaissance")}
                errors={errors.birthDate2}
                touched={touched.birthDate2}
                disabled={isDisabled2 && !enableBirthDateTutor2IfEmpty && true}
                id="birthDate2"
                values={values.birthDate2}
                onChange={(value) => setFieldValue("birthDate2", value)}
              />
            </div>
            <div className={styles.wrapper}>
              <FormDropdownField
                label={t("app.suivezVosContratsPartner.Civilite")}
                errors={errors.civility2}
                touched={touched.civility2}
                id="civility2"
                dataKey="key"
                textField="label"
                data={[{ ...objAucun }, ...(referencialData?.CIVILITY || [])]}
                disabled={isDisabled2 && !enableCivilityTutor2IfEmpty && true}
                values={values.civility2}
                onChange={(newValue) => setFieldValue("civility2", newValue)}
              />
              {/* <FormInputField
                label={t("app.suivezVosContratsPartner.SocialSecurityNumber")}
                errors={errors.socialNumber2}
                touched={touched.socialNumber2}
                id="socialNumber2"
                type="text"
                min="0"
                values={values.socialNumber2}
                onChange={(e) => setFieldValue("socialNumber2", e.target.value)}
              /> */}
            </div>

            <div className={styles.wrapper}>
              <FormInputField
                label={t("app.suivezVosContratsPartner.Fonction")}
                errors={errors.function2}
                touched={touched.function2}
                id="function2"
                type="text"
                min="0"
                disabled={isDisabled2 && !enableFunctionTutor2IfEmpty && true}
                values={values.function2}
                onChange={(e) => setFieldValue("function2", e.target.value)}
              />
              <FormDropdownField
                label={t("app.suivezVosContratsPartner.HighestDegree")}
                errors={errors.highestDegree2}
                touched={touched.highestDegree2}
                id="highestDegree2"
                dataKey="key"
                textField="label"
                data={[{ ...objAucun }, ...(referencialData?.APPRENTICESHIP_STUDENT_DIPLOMA || [])]}
                // disabled={isDisabled2 && !enableHighestDegreeTutor2IfEmpty && true}
                values={values.highestDegree2}
                onChange={(newValue) => setFieldValue("highestDegree2", newValue)}
              />
            </div>
            <div className={styles.checkbox_container}>
              <label className={styles.checkbox_label} htmlFor="validMa2">
                {t("app.suivezVosContratsPartner.Habilitation")}
              </label>
              <FormCustomCheckBoxField
                text={(
                  <>
                    {t("app.suivezVosContratsPartner.MaitreDapprentissageHabilite.partOne")}
                    <a href="https://travail-emploi.gouv.fr/IMG/pdf/apprentissage-infographie-role_du_maitre.pdf">
                      {t("app.suivezVosContratsPartner.MaitreDapprentissageHabilite.link")}
                    </a>
                    {t("app.suivezVosContratsPartner.MaitreDapprentissageHabilite.partTwo")}
                  </>
                )}
                id="validMa2"
                checkboxValue={values.validMa2}
                handleChange={(e) => setFieldValue("validMa2", e)}
                error={errors.validMa2}
                touched={touched.validMa2}
              />
            </div>
          </>
        )}

        <div className={styles.submit_btn_to_right}>
          <SubmitFormBtn text={t("app.visualisationOffre.ValidezLesModifications")} />
        </div>
      </form>
    </div>
  );
};

export default Form6;
